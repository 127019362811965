import React, { Component } from 'react';

class Blog extends Component {

  render() {

    return (
      <div className="listing">
      <section className="blog-container">
        <h1>Bloog</h1>

        </section>
        </div>
    );
  }
}

export default Blog;

// <img src={project.icon} alt="icon-" className="project-icon" />
